import React from 'react';
import './stats.css';

const Stats = () => {
  return (
    <section className="stats container my-5">
      <div className="row no-gutters text-center">
        <div className="col-md-3">
          <div className="stat-box bg-danger text-white py-3">
            <h2>12+</h2>
            <p>Years In Business</p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-box bg-primary text-white py-3">
            <h2>100+</h2>
            <p>Happy Clients</p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-box bg-dark text-white py-3">
            <h2>100+</h2>
            <p>Projects Completed</p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="stat-box bg-danger text-white py-3">
            <h2>20+</h2>
            <p>Trained Staff</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stats;
