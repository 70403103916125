import React from 'react';
import './fibresheet.css';
import Footer from '../footer/footer';
import ResinItem from './resinItem';
import ProductHeader from '../../header/product-header';
import Polyester from '../../../images/resins4.jpeg';

const resins = [
    {
      title: "Unsaturated Polyester Resin",
      description: "This is an orthophthalic based, medium reactivity, low viscosity Unsaturated Polyester Resin. This resin is suitable for continuous lamination. It provides excellent transparency and good wet out. The resin differentiates itself with its high hydrolytic resistance and weather ability. It can be pigmented with pigment pastes in order to obtain transparent or opaque colored products. Products from this resin are widely used in parks, garden, warehouses and greenhouses as roof illumination for the buildings.",
      image: [Polyester]
    },

  ];
  


const Unsaturatedpolyster = () => {
    return (
        <div id='fibremat'>
        <ProductHeader></ProductHeader>
        <main>
    <section className="resin-details container">
      {resins.map((resin, index) => (
        <ResinItem key={index} resin={resin} />
      ))}
    </section>
  </main>
  <Footer></Footer>
    </div> 
    );
}

export default Unsaturatedpolyster;
