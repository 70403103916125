import React from 'react';
import './fibresheet.css';
import Footer from '../footer/footer';
import ResinItem from './resinItem';
import ProductHeader from '../../header/product-header';
import Butanox from '../../../images/botanox.jpeg';

const resins = [
    {
      title: "Catalyst- Botanox",
      description: "This product is Methyl Ethyl Ketone Peroxide. A solution in dimethylphthalate used as a curing agent. It is suitable for both hand lay-up and spray-up applications. It is employed in the production of fibreglass reinforced plastics, buttons, polymer concrete/marble and thermoset composites with unsaturated Polyesters and vinyl ester resin curing. Also used in centrifugal casting, continuous casting, filament winding and transfer moulding applications.",
      image: [Butanox]
    },
  ];
  


const Botanox = () => {
    return (
        <div id='fibremat'>
        <ProductHeader></ProductHeader>
        <main>
    <section className="resin-details container">
      {resins.map((resin, index) => (
        <ResinItem key={index} resin={resin} />
      ))}
    </section>
  </main>
  <Footer></Footer>
    </div> 
    );
}

export default Botanox;
