import React, { useState } from 'react';
import './services.css';

const Services = () => {
  // State to manage the visibility of the full text for each service
  const [showMore, setShowMore] = useState({
    fibreglass: false,
    fibremat: false,
    resin: false,
  });

  // Function to toggle the text visibility
  const toggleReadMore = (service) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [service]: !prevShowMore[service],
    }));
  };

  return (
    <div className="services" id="services">
      <div id="top">
        <h1 id="service">Our services</h1>
        <h2 className="services-title">We Provide Superior Fibreglass Roofing Sheet</h2>
      </div>
      <div className="services-list container">
        <div className="service">
          <img src={require('../../../images/fibreglass2.jpeg')} alt="Roof Installations" />
          <h3>Fibreglass sheet</h3>
          <h6 id="paragraph1">
            {showMore.fibreglass
              ? "Fibreglass sheet is an incredibly versatile material, manufactured from extremely fine glass fibres."
              : "Fibreglass sheet is an incredibly versatile material..."}
          </h6>
          <a href='#paragraph1' onClick={() => toggleReadMore('fibreglass')}>
            {showMore.fibreglass ? "Read Less" : "Read More"}
          </a>
        </div>
        <div className="service">
          <img src={require('../../../images/fibremat.jpeg')} alt="Roof Repair" />
          <h3>Fibre mat</h3>
          <h6 id="paragraph2">
            {showMore.fibremat
              ? "These mats have the right strength and durability to protect the slopes from erosion, while allowing vegetation to flourish. They can dissipate the energy of flowing water and also absorb the excess solar radiation."
              : "These mats have the right strength and durability..."}
          </h6>
          <a href='#paragraph2' onClick={() => toggleReadMore('fibremat')}>
            {showMore.fibremat ? "Read Less" : "Read More"}
          </a>
        </div>
        <div className="service">
          <img src={require('../../../images/resins4.jpeg')} alt="Leak Repair" />
          <h3>Unsaturated Polyester Resin</h3>
          <h6 id="paragraph3">
            {showMore.resin
              ? "Unsaturated polyester resins are mainly used for glass fiber reinforced plastics (FRP). Having excellent tensile strength, bending strength, impact strength, heat resistance, corrosion resistance and electric property, they are widely used for housing equipment, construction material and transportation equipment."
              : "Unsaturated polyester resins are mainly used for..."}
          </h6>
          <a href='#paragraph3' onClick={() => toggleReadMore('resin')}>
            {showMore.resin ? "Read Less" : "Read More"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Services;
