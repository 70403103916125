import React from 'react';
import './clients.css';
import Mikano from '../../images/mikano.png';
import AfricanSteel from '../../images/african steel.jpeg';
import StandardAluminum from '../../images/standard aluminium.png';
import TowerRollingMill from '../../images/Tower aluminiun.jpeg';
import StudioPress from '../../images/studio press.jpeg';

const clients = [
    { id: 1, name: 'Mikano', logo: Mikano },
    { id: 2, name: 'African Steel Company', logo: AfricanSteel },
    { id: 3, name: 'Standard Aluminum', logo: StandardAluminum },
    { id: 4, name: 'Tower Rolling Mill', logo: TowerRollingMill },
    { id: 5, name: 'Studio Press Nig. Ltd', logo: StudioPress },
];

const Clients = () => {
    return (
        <div className="clients-section">
            <div className="clients-text">
                <h2>Our Clients</h2>
                <p>
                Over the years, we have worked with numerous esteemed industries, showcasing our dedication to excellence.
                </p>
            </div>
            <div className="clients-logos">
                {clients.map(client => (
                    <div className="client-logo" key={client.id}>
                        <img src={client.logo} alt={client.name} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Clients;
