import React, { useEffect } from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import './gallery.css';

const Gallery = () => {
    useEffect(() => {
        new Swiper(`[unique-script-id="w-w-dm-id"] .mySwiper`, {
            slidesPerView: 3,
            spaceBetween: 30,
            pagination: {
                el: `[unique-script-id="w-w-dm-id"] .swiper-pagination`,
                clickable: true,
            },
            autoplay: {
                delay: 5000, // 5 seconds
                disableOnInteraction: false,
            },
            breakpoints: {
                200: {
                    slidesPerView: 1
                },
                501: {
                    slidesPerView: 1.5
                },
                769: {
                    slidesPerView: 2.5,
                    spaceBetween: 10
                },
                1025: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
            }
        });
    }, []);

    const images = [
        require('../../images/gallery1.jpeg'),
        require('../../images/gallery2.jpeg'),
        require('../../images/gallery3.jpeg'),
        require('../../images/gallery4.jpeg'),
        require('../../images/gallery5.jpeg'),
        require('../../images/gallery6.jpeg'),
        require('../../images/gallery7.jpeg'),
        require('../../images/gallery8.jpeg'),
        require('../../images/gallery9.jpeg'),
        require('../../images/gallery10.jpeg'),
        require('../../images/gallery11.jpeg'),
        require('../../images/gallery12.jpeg'),
        require('../../images/gallery13.jpeg'),
        require('../../images/gallery14.jpeg'),
        require('../../images/gallery15.jpeg'),
        require('../../images/gallery16.jpeg'),
        require('../../images/gallery17.jpeg'),
        require('../../images/gallery18.jpeg'),
    

    ];

    return (
        <div className="gallery" unique-script-id="w-w-dm-id">
            <div className="responsive-container-block bg">
                <div className="responsive-container-block">
                    <p className="text-blk title">Gallery</p>
                    <p className="text-blk heading">
                    Explore our collection of beautiful images showcasing our work and products. Each image captures the quality and dedication we put into every project.  
                    </p>
                </div>
                <div className="swiper-container mySwiper">
                    <div className="swiper-wrapper">
                        {images.map((src, index) => (
                            <div className="swiper-slide" key={index}>
                                <img className="im" alt={`img-${index}`} src={src} />
                            </div>
                        ))}
                    </div>
                    <div className="swiper-pagination container-block"></div>
                </div>
            </div>
        </div>
    );
};

export default Gallery;
