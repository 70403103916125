import React from 'react';
import './faq.css';
import Accordion from 'react-bootstrap/Accordion';

const Faq = () => {
    return (
        <div className='faq-section' id='faq'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5 titlee'>Frequently asked questions</h2>
            <Accordion defaultActiveKey="" flush>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header className='faq-h'>What is Fibreglass?</Accordion.Header>
                    <Accordion.Body className='faq-p'>
                    Fibreglass is a thin, lightweight, translucent panel often referred to as a skylight roofing panel.  It's  erected on a roof primarily to increase the amount of light that enters the building.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header className='faq-h'>What are the benefits of fibreglass roofing?</Accordion.Header>
                    <Accordion.Body className='faq-p'>
                    <ul>
                                <li><strong>Low Maintenance:</strong> Only occasional cleaning needed; no updates, patching, or re-sealing required. Ensures no leaks during heavy downpours.</li>
                                <li><strong>Reduction of Faults via Seamless Surface:</strong> No seams means fewer issues like bubbling or water infiltration.</li>
                                <li><strong>Long-Lasting Roofing:</strong> Lifespan of at least 20 years. Higher initial cost but potentially cheaper over time due to durability.</li>
                                <li><strong>Better Indoor Lighting Provision:</strong> Translucent panels increase natural light and reduce need for artificial lighting.</li>
                                <li><strong>Excellent Heat Absorption:</strong> Absorbs more heat in colder climates, helping manage temperature and saving energy.</li>
                                <li><strong>Higher Durability:</strong> Can withstand larger temperature ranges without warping or breaking.</li>
                                <li><strong>Suitable for Highly Corrosive Environments:</strong> Resistant to corrosion, ideal for coastal and marine conditions.</li>
                                <li><strong>Solid Appearance:</strong> Can be molded into various designs and colors to mimic other materials.</li>
                                <li><strong>Lightweight Roofing:</strong> Lighter than clay, concrete, or wood, making it a popular choice for roofs and window panels.</li>
                            </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header className='faq-h'>How long will a fibre roof last?</Accordion.Header>
                    <Accordion.Body className='faq-p'>
                    The evidence from research suggests that with proper care, it's reasonable to expect a lifespan of anywhere between 30 - 50 years.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                    <Accordion.Header className='faq-h'>Why do your product or service stand out</Accordion.Header>
                    <Accordion.Body className='faq-p'>
                    Our product stand out because we deliver quality products within the target framework
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div> 
    </div>
  )
}


export default Faq;

