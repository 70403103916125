import Homepage from './pages/homepage/homepage';
import './App.css';
import { Route, Routes } from 'react-router';
import Header from './pages/header/header';
import Contact from './pages/contact/contact';
import About from './pages/about/about';
import Footer from './pages/homepage/footer/footer';
import Gallery from './pages/gallery/gallery';
import Services from './pages/homepage/services/services';
import Fibresheet from './pages/homepage/services/fibresheet';
import Fibremat from './pages/homepage/services/fibremat';
import Isoresin from './pages/homepage/services/isoresin';
import Gelcoat from './pages/homepage/services/gelcoat';
import Pvcnylon from './pages/homepage/services/pvcnylon';
import Vinylresin from './pages/homepage/services/vinylresin';
import Unsaturatedpolyester from './pages/homepage/services/unsaturatedpolyester';
import Botanox from './pages/homepage/services/botanox';
import Promoter from './pages/homepage/services/promoter';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={ <Homepage></Homepage>}></Route>
        <Route path='/header' element={ <Header></Header>}></Route>
        <Route path='/contact' element={<Contact></Contact>}></Route>
        <Route path='/product' element={<Services></Services>}></Route>
        <Route path='/about' element={<About></About>}></Route>
        <Route path='/footer' element={<Footer></Footer>}></Route>
        <Route path='/gallery' element={<Gallery></Gallery>}></Route>
        <Route path='/fibresheet' element={<Fibresheet></Fibresheet>}></Route>
        <Route path='/fibremat' element={<Fibremat></Fibremat>}></Route>
        <Route path='/isoresin' element={<Isoresin></Isoresin>}></Route>
        <Route path='/gelcoat' element={<Gelcoat></Gelcoat>}></Route>
        <Route path='/pvcnylon' element={<Pvcnylon></Pvcnylon>}></Route>
        <Route path='/botanox' element={<Botanox> </Botanox>}></Route>
        <Route path='/promoter' element={<Promoter></Promoter>}></Route>
        <Route path='/vinylresin' element={<Vinylresin></Vinylresin>}></Route>
        <Route path='/unsaturatedpolyester' element={<Unsaturatedpolyester></Unsaturatedpolyester>}></Route>
      </Routes>
   
    </div>
  );
}

export default App;
