import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './header.css';
import Logo from '../../images/logos.png';

const Header = () => {
    const [isProductsOpen, setIsProductsOpen] = useState(false);
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

    const toggleProducts = () => {
        setIsProductsOpen(prevState => !prevState);
    };

    const toggleNavbar = () => {
        setIsNavbarCollapsed(prevState => !prevState);
    };

    const closeNavbar = () => {
        setIsNavbarCollapsed(true);
        setIsProductsOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 991) {
                setIsNavbarCollapsed(true);
                setIsProductsOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <header className="header">
            <div className="container">
                {/* Wider Screen Section */}
                <div className="wider-screen d-none d-lg-block">
                    <div className="top-section">
                        <div className="phone-info">
                            <p className="mb-0">Phone: (+234) 8069257764</p>
                        </div>
                        <div className="address-info">
                            <p className="mb-0">Address: Abebi, Ota. Ogun state</p>
                        </div>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/share/amJJ8KupGepefymg/?mibextid=qi2Omg" className="me-2">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="https://www.twitter.com" className="me-2">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="https://www.instagram.com">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://www.linkedin.com">
                                <i className="fab fa-linkedin"></i>
                            </a>
                        </div>
                    </div>

                    <div className="middle-section">
                        <div>
                            <img
                                src={Logo}
                                alt="Logo"
                                className="d-inline-block align-top brand-logo"
                                style={{ height: '50px', maxWidth: '100%' }}
                            />
                        </div>
                        <nav>
                            <ul className="nav justify-content-center">
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#home">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#about">About</a>
                                </li>
                           <li className="nav-item dropdown" onClick={toggleProducts}>
                                    <a className="nav-link text-white dropdown-toggle" href="#products" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded={isProductsOpen}>
                                        Products
                                    </a>
                                    <div className={`dropdown-menu ${isProductsOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                                        <div className="dropdown-grid">
                                            <div><a className="dropdown-item" href="/fibresheet">Fibreglass Sheet</a></div>
                                            <div><a className="dropdown-item" href="/fibremat">Fibremat</a></div>
                                            <div><a className="dropdown-item" href="/unsaturatedpolyester">Unsaturated Polyester Resin</a></div>
                                            <div><a className="dropdown-item" href="./vinylresin">Vinylester Resin</a></div>
                                            <div><a className="dropdown-item" href="/isoresin">ISO Resin</a></div>
                                            <div><a className="dropdown-item" href="/gelcoat">Gelcoat</a></div>
                                            <div><a className="dropdown-item" href="/pvcnylon">PVC Nylon</a></div>
                                            <div><a className="dropdown-item" href="/botanox">Catalyst Botanox</a></div>
                                            <div><a className="dropdown-item" href="/promoter">Accelerator Promoter</a></div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#gallery">Workshop</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#faq">FAQ</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#contact">Contact</a>
                                </li>
                            </ul>
                        </nav>
                        <div className="mt-3">
                            <a href="#contact" className="btn custom-btn">BOOK A CALL</a>
                        </div>
                    </div>
                    <div className="bottom-section">
                        <h1 className="text-white">We are Commercial</h1>
                        <h1 className="text-white">Roofing Experts</h1>
                        <p>Need Durable Fibresheet glass? we are just a call away</p>
                        <a href="tel:08069257764">
                            <button className="btn custom-btnn">SPEAK TO AN EXPERT</button>
                        </a>
                    </div>
                </div>

                {/* Smaller Screen Section */}
                <div className="smaller-screen d-lg-none">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <img
                                src={Logo}
                                alt="Logo"
                                className="d-inline-block align-top brand-logo"
                                style={{ height: '50px', maxWidth: '100%' }}
                            />
                            <button
                                className="navbar-toggler ms-auto"
                                type="button"
                                onClick={toggleNavbar}
                                aria-controls="navbarToggleExternalContent3"
                                aria-expanded={!isNavbarCollapsed}
                                aria-label="Toggle navigation"
                            >
                                <i className="fas fa-bars"></i>
                            </button>
                        </div>
                    </nav>
                    <div className={`collapse ${isNavbarCollapsed ? '' : 'show'}`} id="navbarToggleExternalContent3">
                        <div className="shadow-3 p-4">
                            <nav>
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#home" onClick={closeNavbar}>Home</a>
                                    </li>
                                    <li className="nav-item dropdown" onClick={toggleProducts}>
                                        <a className="nav-link dropdown-toggle" href="#products" id="navbarDropdownSmall" role="button" aria-haspopup="true" aria-expanded={isProductsOpen}>
                                            Products
                                        </a>
                                        <div className={`dropdown-menu ${isProductsOpen ? 'show' : ''}`} aria-labelledby="navbarDropdownSmall">
                                            <a className="dropdown-item" href="/fibresheet" onClick={closeNavbar}>Fibreglass Sheet</a>
                                            <a className="dropdown-item" href="/fibremat" onClick={closeNavbar}>Fibremat</a>
                                            <a className="dropdown-item" href="/unsaturatedpolyester" onClick={closeNavbar}>Unsaturated Polyester Resin</a>
                                            <a className="dropdown-item" href="/vinylresin" onClick={closeNavbar}>Vinylester Resin</a>
                                            <a className="dropdown-item" href="/isoresin" onClick={closeNavbar}>ISO Resin</a>
                                            <a className="dropdown-item" href="/gelcoat" onClick={closeNavbar}>Gelcoat</a> 
                                            <a className="dropdown-item" href="/pvcnylon" onClick={closeNavbar}>PVC Nylon</a>
                                            <a className="dropdown-item" href="/botanox" onClick={closeNavbar}>Catalyst Botanox</a>
                                            <a className="dropdown-item" href="/promoter" onClick={closeNavbar}>Accelerator Promoter</a>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#about" onClick={closeNavbar}>About</a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link text-white" href="#gallery">Workshop</a>
                                </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#faq" onClick={closeNavbar}>FAQ</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#contact" onClick={closeNavbar}>Contact</a>
                                    </li>
                                </ul>
                            </nav>
                            <div className="social-iconss">
                                <a href="https://www.facebook.com/share/amJJ8KupGepefymg/?mibextid=qi2Omg" className="me-2">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a href="https://www.twitter.com" className="me-2">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a href="https://www.instagram.com">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a href="https://www.linkedin.com">
                                    <i className="fab fa-linkedin"></i>
                                </a>
                            </div>
                            <div className="mt-3">
                                <a href="#contact" className="btn custom-btn">BOOK A CALL</a>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section">
                        <h1 className="text-white">We are Commercial</h1>
                        <h1 className="text-white">Roofing Experts</h1>
                        <p>Need Durable Fibresheet glass? we are just a call away</p>
                        <a href="tel:08069257764">
                            <button className="btn custom-btnn" id='small-screen-btn'>SPEAK TO AN EXPERT</button>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
