import React from 'react';
import './additionalservices.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';

const AdditionalServices = () => {
  return (
    <div className="additional-services">
      <div className="content">
        <h3 id='service'>Additional Services</h3>
        <h2 className="title">We Also Offer</h2>
        <h6 className="description">
        As a company dedicated to delivering superior products, we offer a wide range of additional services to meet your needs. From specialized resins to high-performance coatings, our supplementary offerings ensure that you have the best materials for your projects.
        </h6>
        <h6 className='list'><FontAwesomeIcon icon={faArrowCircleRight}className='icon'></FontAwesomeIcon> Vinylester Resin</h6>
        <h6 className='list'><FontAwesomeIcon icon={faArrowCircleRight}className='icon'></FontAwesomeIcon> ISO Resin</h6>
        <h6 className='list'><FontAwesomeIcon icon={faArrowCircleRight}className='icon'></FontAwesomeIcon> Gelcoat</h6>
        <h6 className='list'><FontAwesomeIcon icon={faArrowCircleRight}className='icon'></FontAwesomeIcon> PVC Nylon</h6>
        <h6 className='list'><FontAwesomeIcon icon={faArrowCircleRight}className='icon'></FontAwesomeIcon> Catalyst - Botanox</h6>
        <h6 className='list'><FontAwesomeIcon icon={faArrowCircleRight} className='icon'></FontAwesomeIcon> Accelerator - Promoter</h6>
        <button className="know-more-btn">Know More</button>
      </div>
      <div className="image">
        <img src={require('../../../images/additional-product.png')} alt="Construction worker" />
      </div>
    </div>
  );
};

export default AdditionalServices;
