import React from 'react';
import './contact.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const Contact = () => {
  return (
    <Container className="my-5" id='contact'>
      <Row className="align-items-center">
        <Col md={6} className="mb-4 mb-md-0">
          <div className="p-3 address">
            <h5 className="text-muted" id='contacts-head' >Offices</h5>
            <h2 className="font-weight-bold" id='contacts-subheader address'>Head Office</h2>
            <h6 id='address'>We are always ready to receive you and give you a tour of our facility</h6>
            <h6 id='address'><strong>Address:</strong>30, Oke Alase street, by Jesus Army Church, Abebi, Ota. Ogun state</h6>
            <h6 id='address'><strong>Phone:</strong> 08069257764</h6>
            <h6 id='address'><strong>Email1:</strong> rsoughtout@gmail.com</h6>
            <h6 id='address'><strong>Email2:</strong> info@soughtoutroofing.com</h6>
          </div>
        </Col>
        <Col md={6}>
          <div className="p-3 bg-light">
            <h5 className="text-muted" id='contacts-head'>Get in Touch </h5>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Enter your full name</Form.Label>
                <Form.Control type="text" placeholder="Full Name" />
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone number</Form.Label>
                <Form.Control type="text" placeholder="Phone Number" />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Your email</Form.Label>
                <Form.Control type="email" placeholder="Email" />
              </Form.Group>
              <Form.Group controlId="formServiceDescription">
                <Form.Label>Service Description</Form.Label>
                <Form.Control type="text" placeholder="Service Description" />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Message" />
              </Form.Group>
              <Button variant="danger" type="submit">SUBMIT</Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;