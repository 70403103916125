import React from 'react';
import './resinItem.css';

const ResinItem = ({ resin }) => {
  return (
    <div className="resin-item">
      <img src={resin.image} alt={resin.title} />
      <div>
      <h2>{resin.title}</h2>
      <p>{resin.description}</p>
      </div>
      

    </div>
  );
};

export default ResinItem;
