
import './homepage.css';
import Header from '../header/header';
import Footer from './footer/footer';
import About from '../about/about';
import Stats from './stats';
import Reasons from '../reasons/reasons';
import AdditionalServices from './additionalservices/additionalservices';
import Services from './services/services';
import Contact from '../contact/contact';
import Faq from '../faq/faq';
import WhatsappIcon from './whatsapicon';
import Gallery from '../gallery/gallery';
import Clients from '../clients/clients';


const Homepage = () => {

    return (
        <div id='home'>
            <Header />
            <About></About>
            <Stats></Stats>
            <Services></Services>
            <AdditionalServices></AdditionalServices>
            <Reasons></Reasons>
            <Gallery></Gallery>
            <Clients></Clients>
           <Faq></Faq>
           <Contact></Contact>
            <Footer></Footer>
            <WhatsappIcon></WhatsappIcon>
        </div>
    );
}
export default Homepage;
