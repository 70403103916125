import React from 'react';
import './fibresheet.css';
import Footer from '../footer/footer';
import ResinItem from './resinItem';
import ProductHeader from '../../header/product-header';
import Fibrematt from '../../../images/fibremat.jpeg';

const resins = [
    {
      title: "FibreMat (Woven Mat)",
      description: "Fiberglass mat is commonly used for applications that demand good impact resistance and moderate strength, such as boat hulls and architectural structures, skylight sheets.",
      image: [Fibrematt]
    },
   
    // Add more resin objects as needed
  ];
  


const Fibremat = () => {
    return (
        <div id='fibremat'>
            <ProductHeader></ProductHeader>
            <main>
        <section className="resin-details container">
          {resins.map((resin, index) => (
            <ResinItem key={index} resin={resin} />
          ))}
        </section>
      </main>
      <Footer></Footer>
        </div>
    );
}

export default Fibremat;
