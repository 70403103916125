import React from 'react';
import './fibresheet.css';
import Footer from '../footer/footer';
import ResinItem from './resinItem';
import ProductHeader from '../../header/product-header';
import isoressin from '../../../images/isoresin.jpeg'

const resins = [
    {
      title: "Iso Resin",
      description: "Iso resin is a wax free resin. This resin is generally used in mold making because of its extreme durability and protection against heat and chemicals. ",
      image: [isoressin]
    },
  
  ];

const Isoresin = () => {
    return (
        <div id='isoresin'>
        <ProductHeader></ProductHeader>
        <main>
    <section className="resin-details container">
      {resins.map((resin, index) => (
        <ResinItem key={index} resin={resin} />
      ))}
    </section>
  </main>
  <Footer></Footer>
    </div>
    );
}

export default Isoresin;
