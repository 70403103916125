import React from 'react';
import './productlist.css';

import Fibreglass from '../../../images/fibreglass5.jpeg';
import Fibreglass1 from '../../../images/fibreglass 1.webp';
import Fibreglass2 from '../../../images/gallery4.jpeg';
import Fibreglass3 from '../../../images/gallery2.jpeg';
import Fibreglass4 from '../../../images/fibresheet6.jpeg';
import Fibreglass5 from '../../../images/coloredfibreglass2.jpeg';
import Fibreglass6 from '../../../images/fibreglass4.jpeg';
import Fibreglass7 from '../../../images/coloredfibresheet.jpeg';

const products = [
    {
        id: 1,
        name: '1mm Thick Plain Fibreglass Sheet',
        Price: '₦7,500.00',
        image: Fibreglass,
        thickness: '1mm'
    },
    {
        id: 2,
        name: '1mm Thick Colored Fibreglass Sheet',
        Price: '₦7,800.00',
        image: Fibreglass1,
        thickness: '1mm'
    },
    {
        id: 3,
        name: '2mm Thick Plain Fibreglass Sheet',
        Price: '₦9,000.00',
        image: Fibreglass2,
        thickness: '2mm'
    },
    {
        id: 4,
        name: '2mm Thick Colored Fibreglass Sheet',
        Price: '₦9,300.00',
        image: Fibreglass3,
        thickness: '2mm'
    },
    {
        id: 5,
        name: '3mm Thick Plain Fibreglass Sheet',
        Price: '₦20,000.00',
        image: Fibreglass4,
        thickness: '3mm'
    },
    {
        id: 6,
        name: '3mm Thick Colored Fibreglass Sheet',
        Price: '₦21,000.00',
        image: Fibreglass5,
        thickness: '3mm'
    },
    {
        id: 7,
        name: '4mm Thick Colored Fibreglass Sheet',
        Price: '₦28,000.00',
        image: Fibreglass6,
        thickness: '4mm'
    },
    {
        id: 8,
        name: '4mm Thick Colored Fibreglass Sheet',
        Price: '₦29,000.00',
        image: Fibreglass7,
        thickness: '4mm'
    },
];

const ProductList = () => {
    const handleEnquire = (product) => {
        const message = `Hello, I want to make further enquiry on the ${product.thickness} ${product.name}`;
        const encodedMessage = encodeURIComponent(message);
        window.open(`https://wa.me/+2348069257764?text=${encodedMessage}`, '_blank');
    };

    return (
        <div className="product-list">
            <h3>Price List</h3>
            <div className="product-grid">
                {products.map(product => (
                    <div className="product-card" key={product.id}>
                        <div className="product-image">
                            <img src={product.image} alt={product.name} />
                        </div>
                        <div className="product-details">
                            <h2>{product.name}</h2>
                            <p className="new-price">{product.Price}</p>
                            <button onClick={() => handleEnquire(product)}>Enquire</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductList;
