import React from 'react';
import './fibresheet.css';
import Footer from '../footer/footer';
import ResinItem from './resinItem';
import ProductHeader from '../../header/product-header';
import pvcnylonn from '../../../images/pvcnylon2.webp';

const resins = [
    {
      title: "PVC Nylon",
      description: "It is used for Laminating",
      image: [pvcnylonn]
    },
  
  ];

const Pvcnylon = () => {
    return (
        <div id='fibremat'>
        <ProductHeader></ProductHeader>
        <main>
    <section className="resin-details container">
      {resins.map((resin, index) => (
        <ResinItem key={index} resin={resin} />
      ))}
    </section>
  </main>
  <Footer></Footer>
    </div> 
    );
}

export default Pvcnylon;
