import React from 'react';
import './fibresheet.css';
import Footer from '../footer/footer';
import ResinItem from './resinItem';
import ProductHeader from '../../header/product-header';
import Gellcoat from '../../../images/gelcoat.jpeg'

const resins = [
    {
      title: "Gel Coat",
      description: "Gel coat is the most common surface coating used in the fabrication and repair of fiberglass reinforced products. Our Low Emissions Gel Coat is a specially formulated two-part polyester resin that is designed to be the first layer of resin applied in a mold when making a polyester or vinyl ester composite part.",
      image: [Gellcoat]
    },
  
  ];


const Gelcoat = () => {
    return (
        <div id='fibremat'>
        <ProductHeader></ProductHeader>
        <main>
    <section className="resin-details container">
      {resins.map((resin, index) => (
        <ResinItem key={index} resin={resin} />
      ))}
    </section>
  </main>
  <Footer></Footer>
    </div> 
    );
}

export default Gelcoat;
