import React from 'react';
import './fibresheet.css';
import Footer from '../footer/footer';
import ResinItem from './resinItem';
import ProductHeader from '../../header/product-header';
import Accelerator from '../../../images/resins3.jpeg';

const resins = [
    {
      title: "Accelerator Promoter",
      description: "This product is Cobalt (ll) 2-Ethylhexanoate, 6% Co in solvent mixture. It is a promoter and a curing agent. It is suitable for both hand lay-up and spray-up applications. It is employed in the production of fibreglass reinforced plastics, buttons, polymer concrete/marble and thermoset composites with unsaturated Polyesters and vinyl ester resin curing. Also used in centrifugal casting, continuous casting, filament winding and transfer moulding applications. Also available are lower concentrates  versions (1) Accelerator Cobalt 1% Co, in solvent mixture AND (2) Accelerator Cobalt 0.5% Co, in solvent mixture.",
      image: [Accelerator]
    },

  ];
  


const Promoter = () => {
    return (
        <div id='fibremat'>
        <ProductHeader></ProductHeader>
        <main>
    <section className="resin-details container">
      {resins.map((resin, index) => (
        <ResinItem key={index} resin={resin} />
      ))}
    </section>
  </main>
  <Footer></Footer>
    </div> 
    );
}

export default Promoter;
