import React from 'react';
import './fibresheet.css';
import Footer from '../footer/footer';
import ResinItem from './resinItem';
import ProductHeader from '../../header/product-header';
import Vinylesteresin from '../../../images/vinylresin.jpeg';

const resins = [
    {
      title: "Vinylester Resin",
      description: "Vinylester Resin:  Vinyl esters are a form of polyester resin that has been strengthened by epoxy resin. They are more chemically resistant and less absorbent of water. It application includes corrosion-resistant industrial tanks & pipes and pultruded profiles. Additionally, vinyl esters are often used in critical components for automotive and marine vehicles.",
      image: [Vinylesteresin]
    },
  
  ];
  


const Vinylresin = () => {
    return (
        <div id='vinylresin'>
        <ProductHeader></ProductHeader>
        <main>
    <section className="resin-details container">
      {resins.map((resin, index) => (
        <ResinItem key={index} resin={resin} />
      ))}
    </section>
  </main>
  <Footer></Footer>
    </div> 
    );
}

export default Vinylresin;
