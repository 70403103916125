import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer text-white">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12">
            <h5 className="text-danger font-size-700">About</h5>
            < p className='abouut last-child'>
            SoughtOut Roofing Sheet Ltd has been one of the leading manufacturing companies of Fibreglass materials since 2012.
            </p>
            <div className="social-icoons">
              <a href= "https://www.facebook.com" ><i className="fab fa-facebook-f"></i></a>
              <a href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
              <a href="https://www.facebook.com" ><i className="fab fa-instagram"></i></a>
              <a href="https://www.linkedin.com"><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <h5 className="text-danger font-size-700">Quick Links</h5>
            <ul>
              <li><a href="#gome">Home</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#contact">Contact</a></li>
              <li><a href="#faq">FAQ</a></li>
            </ul>
          </div>
          <div className="col-md-3 col-12">
            <h5 className="text-danger font-size-700">Our Service</h5>
            <ul>
              <li><a href="#services">Fibreglass Sheet</a></li>
              <li><a href="#services">Fibremat</a></li>
              <li><a href="#services">Resins</a></li>
              <li><a href="#services">Pvc Nylon</a></li>
            </ul>
          </div>
          <div className="col-md-3 col-12">
            <h5 className="text-danger font-size-700">Free Estimate</h5>
            <p className='last-child'>Call Us: +2348069257764</p>
            <p className='last-child'>We offer free estimates and consultations. Our experts are ready to assist you with all your roofing needs. Contact us today to get started!</p>
          </div>
        </div>
        <div className="footer-bottom">
          <p className='paragraph1'>&copy; 2024 SoughtOut Roofing Sheet LTD. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
