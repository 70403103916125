import React from 'react';
import './about.css';

const About = () => {
  return (
    <div className="about-container">
    <div className="about-company" id='about'>
      <div className="image-section">
        <img src={require('../../images/abouut.jpeg')} alt="Roofing work" className="main-image" />
        <img src={require('../../images/gallery5.jpeg')} alt="Roofing worker" className="overlay-image" />
      </div>
      <div className="content-section">
        <h2>About Company</h2>
        <h1>Soughtout Roofing Sheet LTD</h1>
        <p id='content'>
          Soughtout Roofing Sheet LTD has been in existence since 2012. It has been one of the leading manufacturing companies of Fibreglass materials, such as Fibre transparent roofing sheet, fibreglass water tank, fibreglass chemical tank, several related fibreglass constructions, and sales of Fibreglass raw materials.
        </p>
        <p id='content'>
          Our commitment to excellence is reflected in our state-of-the-art manufacturing processes and our adherence to the highest industry standards. We continuously innovate to meet the evolving needs of our customers, providing solutions that are not only reliable and efficient but also environmentally sustainable. At Soughtout Roofing Sheet LTD, customer satisfaction is our top priority, and we strive to build lasting relationships through quality products and exceptional service.
        </p>
        <a href='#reasons'><button className="read-more-btn" >Read More</button></a>
      </div>
      </div>
    </div>
  );
};

export default About;
