import React from 'react';
import './reasons.css';

const reasons = [
  {
    icon: 'fas fa-medal',
    title: 'Quality Material',
    description: 'We provide top-grade fiberglass roofing sheets that guarantee durability and strength.'
  },
  {
    icon: 'fas fa-check-circle',
    title: 'Accredited',
    description: 'Our products and services are fully accredited, ensuring you receive only the best.'
  },
  {
    icon: 'fas fa-users',
    title: 'Trained Workers',
    description: 'Our team consists of highly trained professionals who ensure seamless installation and support.'
  },
  {
    icon: 'fas fa-clock',
    title: 'Time Availability',
    description: 'We are available to assist you round the clock, ensuring your needs are met promptly.'
  },
  {
    icon: 'fas fa-phone-alt',
    title: 'Quick Response',
    description: 'Our customer service team is always ready to respond to your inquiries quickly and efficiently.'
  },
  {
    icon: 'fas fa-hands-helping',
    title: 'Comprehensive Support',
    description: 'We offer comprehensive after-sales support to help you with any issues or maintenance needs.'
  }
];

const Reasons = () => {
  return (
    <section className="reasons" id='reasons'>
      <div className="container">
        <h4 id='why'>Why Us</h4>
        <h2 className="title">Six Reasons For People Choosing Us</h2>
        <div className="row">
          {reasons.map((reason, index) => (
            <div className="col-md-4 col-sm-6 col-12" key={index}>
              <div className="reason-box">
                <i className={reason.icon}></i>
                <h3>{reason.title}</h3>
                <p>{reason.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Reasons;
