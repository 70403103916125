import React from 'react';
import './fibresheet.css';
import Footer from '../footer/footer';
import ResinItem from './resinItem';
import ProductHeader from '../../header/product-header';
import Fibreglass from '../../../images/gallery4.jpeg'
import Fibreglass1 from '../../../images/fibreglass3.jpeg'
import ProductList from './productlist';

const resins = [
    {
      title: "FibreSheet",
      description: "Fibreglass is a thin, lightweight, translucent panel often referred to as a skylight roofing panel.  It's  erected on a roof primarily to increase the amount of light that enters the building.",
      image: [Fibreglass]
    },
    {
      title: "FibreSheet",
      description: "Fiberglass roofing sheets often known as Transparent sheet (TS) FRS, are constructed from unsaturated polyester resin and fiberglass materials. Compared to other plastic roofing sheet options like PVC (polyvinyl chloride) or polycarbonate panels, the fiberglass in these sheets makes them more durable. Roofing sheets made of heavy-duty fiberglass are made to withstand severe weather. They require little maintenance, are corrosion-resistant, and are simple to install.",
      image: [Fibreglass1]
    },
    // Add more resin objects as needed
  ];
  



const Fibresheet = () => {
    return (

        <div id='fibreglass-sheet'>
            <ProductHeader></ProductHeader>
            <main>
        <section className="resin-details container">
          {resins.map((resin, index) => (
            <ResinItem key={index} resin={resin} />
          ))}
        </section>
      </main>
      <ProductList></ProductList>
      <Footer></Footer>
        </div>
    );
}

export default Fibresheet;
